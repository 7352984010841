import * as React from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import '../styles/_bootstrap.scss'
import './global.scss';
import './index.scss';
class Brands extends React.PureComponent<{},{}> {
    public render() {
        return (
            <>
            <Header />
            <div className="page">
                <div className="container-fluid hero">
                    <section className="section platformPartners hero-big background-gradient text-left">
                    
                        <div className=" hero-with-image container" style={{padding: "7% 0 7% 0"}}>
                        <div className="col-xs-12 padding-none padding-top-M" style={{transform: "rotate(3deg)"}}>
                                <h2 className="padding-bottom-M" style={{transform: "none"}}>We&#8217;ve curated long term partnerships with leading and well respected brands</h2>
                            </div>
                            <div className="col-sm-6 col-xs-12 padding-none"  style={{transform: "rotate(3deg)"}}>
                                <p className="text-XL">We&#8217;re a strategically built channel platform, connecting global brands with over 3000 resellers &amp; retailers. 
                                This network has access to inventory, fulfilment, EDI integrations and support whenever they need it.</p>
                            </div>
                        </div>
                            
                    </section>
                </div>

                <div className="container-fluid padding-none body">
                    <section className="section brands">
                        <div className="container">
                            <div className="col-xs-12 padding-none">
                                <h2>Proud to be partnering with</h2>
                            </div>
                            <div className="brandsList text-left hidden-xs hidden-sm">
                                <div className="col-xs-12 margin-top-L ">
                                    {/* <h4><i aria-hidden="true" className="fa fa-asterisk"></i> Exclusive Distributor <i aria-hidden="true" className="fa fa-arrow-up"></i> Largest Distributor</h4> */}
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="canon">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-asterisk"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>World-leading innovator and provider of imaging technology.</h4>
                                            <h5><a className="btn btn-slim">Cameras</a> <a className="btn btn-slim">Video Cameras</a> <a className="btn btn-slim">Lenses</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="sony">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-arrow-up"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Fulfilling curiosity with innovative technology and sleek designs.</h4>
                                            <h5><a className="btn btn-slim">Cameras</a> <a className="btn btn-slim">Video Cameras</a> <a className="btn btn-slim">Lenses</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="samsung">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-info"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Bettering everyday experiences with innovative products.</h4>
                                            <h5><a className="btn btn-slim">Cameras</a> <a className="btn btn-slim">Audio</a> <a className="btn btn-slim">Televisions</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="praktica">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-asterisk"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Combining heritage optical knowledge with cutting edge innovation.</h4>
                                            <h5><a className="btn btn-slim">Cameras</a> <a className="btn btn-slim">Sport Optics</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="kodak">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-info"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Delivering leading and innovative solutions for digital imaging.</h4>
                                            <h5><a className="btn btn-slim">Cameras</a> <a className="btn btn-slim">Video Cameras</a> <a className="btn btn-slim">Security</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="panasonic">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-arrow-up"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Providing cutting edge consumer products and solutions.</h4>
                                            <h5><a className="btn btn-slim">Cameras</a> <a className="btn btn-slim">Video Cameras</a> <a className="btn btn-slim">Lenses</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="olympus">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-arrow-up"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Leading provider of innovative optical and digital products.</h4>
                                            <h5><a className="btn btn-slim">Cameras</a> <a className="btn btn-slim">Lenses</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="ricoh">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-info"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Trusted technology leader for innovative products.</h4>
                                            <h5><a className="btn btn-slim">Cameras</a> <a className="btn btn-slim">Video Cameras</a> <a className="btn btn-slim">Lenses</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="jvc">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-info"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Leading developer of sophisticated audio and video products.</h4>
                                            <h5><a className="btn btn-slim">Audio</a> <a className="btn btn-slim">Video Cameras</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="fujifilm">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-arrow-up"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Creating innovative, cutting-edge products and effective solutions.</h4>
                                            <h5><a className="btn btn-slim">Cameras</a> <a className="btn btn-slim">Lenses</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="lg">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-info"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Leading producer of innovative technologies and unique products.</h4>
                                            <h5><a className="btn btn-slim">Televsions</a> <a className="btn btn-slim">Cameras</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="transcend">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-info"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Leading global brand of digital storage, multimedia & industrial products</h4>
                                            <h5><a className="btn btn-slim">Storage</a><a className="btn btn-slim">Multimedia</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="garmin">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-info"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Delivering innovative GPS technology with compelling designs.</h4>
                                            <h5><a className="btn btn-slim">Smart Wearables</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="proper">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-asterisk"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Creating innovative, simple and durable products.</h4>
                                            <h5><a className="btn btn-slim">Security</a> <a className="btn btn-slim">Brackets</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="kurio">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-asterisk"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>Leading developer of innovative and educational products.</h4>
                                            <h5><a className="btn btn-slim">Smart Devices</a></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-lg-3">
                                    <div className="sigma">
                                        <div className="overlay"></div>
                                        <div className="corner-overlay-content">
                                            <i aria-hidden="true" className="fa fa-asterisk"></i>
                                        </div>
                                        <div className="overlay-content">
                                            <h4>World-leading provider of top quality optical technologies.</h4>
                                            <h5><a className="btn btn-slim">Lenses</a></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="partners-list hidden-md hidden-lg hidden-xl">
                                <a href="http://www.canon.co.uk/" target="_blank">
                                    <img alt="Canon" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/canon.gif" />
                                </a>
                                <a href="https://www.sony.co.uk/" target="_blank">
                                    <img alt="Sony" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/sony.png" />
                                </a>
                                <a href="http://www.samsung.com/uk/home/" target="_blank">
                                    <img alt="Samsung" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/samsung.jpg" />
                                </a>
                                <a href="https://www.praktica.com/" target="_blank">
                                    <img alt="Praktica" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/praktica.jpg" />
                                </a>
                                <a href="http://www.kodakpixpro.com/Europe/" target="_blank">
                                    <img alt="Kodak" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/kodak.png" />
                                </a>
                                <a href="http://www.olympus.co.uk/" target="_blank">
                                    <img alt="Olympus" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/olympus.png" />
                                </a>
                                <a href="http://www.ricoh.co.uk/" target="_blank">
                                    <img alt="Ricoh" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/ricoh.png" />
                                </a>
                                <a href="http://uk.jvc.com/" target="_blank">
                                    <img alt="JVC" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/jvc.gif" />
                                </a>
                                <a href="http://www.fujifilm.com/" target="_blank">
                                    <img alt="Fujifilm" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/fuji.gif" />
                                </a>
                                <a href="http://www.properav.com/" target="_blank">
                                    <img alt="Proper AV" className="partners-logo MD-logo" src="https://assets.direktek.co.uk/images/home/partners/proper.png" />
                                </a>
                            </div>
                        </div>
                    </section>

                    <section className="section brands background-grey">
                        <div className="container padding-none">
                            <div className="col-xs-12">
                                <h2>We&#8217;ve fostered a culture of expertise in our product categories</h2>
                            </div>
                            <div className="col-md-10 col-md-offset-1 col-xs-12 margin-top-XL text-L text-center">
                                <div className="col-xs-6 col-sm-3">
                                    <div className="category-grid">
                                        <a href="#"><img src="/images/cats/photo_red.png" />
                                            <p>Photo</p></a>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-3" style={{ background: "#f5f5f5" }}>
                                    <div className="category-grid">
                                        <a href="#"  style={{cursor:'default' as 'default', pointerEvents:'none'}}><img src="/images/cats/video_red.png" className="disabled"/>
                                            <p>Video</p></a>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-3">
                                    <div className="category-grid">
                                        <a href="#" className="disabled"><img src="/images/cats/optics_red.png" className="disabled"/>
                                            <p>Optics</p></a>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-3" style={{ background: "#f5f5f5" }}>
                                    <div className="category-grid">
                                        <a href="#"><img src="/images/cats/sound_vision_red.png" />
                                            <p>Sound &amp; Vision</p></a>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-3" style={{ background: "#f5f5f5" }}>
                                    <div className="category-grid">
                                        <a href="#"><img src="/images/cats/brackets_mounts_red.png" />
                                            <p>Brackets &amp; Mounts</p></a>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-3">
                                    <div className="category-grid">
                                        <a href="#"><img src="/images/cats/smart_tech_red.png" />
                                            <p>Smart Tech</p></a>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-3" style={{ background: "#f5f5f5" }}>
                                    <div className="category-grid">
                                        <a href="#"><img src="/images/cats/home_office_red.png" />
                                            <p>Home &amp; Office</p></a>
                                    </div>
                                </div>
                                <div className="col-xs-6 col-sm-3">
                                    <div className="category-grid">
                                        <a href="#"><img src="/images/cats/digital_storage_red.png" />
                                            <p>Digital Storage</p></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section brands">
                        <div className="container padding-none text-left">
                            <div className="col-md-6 col-sm-12">
                                <div className="prod-request">
                                    <h2 className="padding-bottom-M">Request for Products</h2>
                                    <p className="text-M">Our market is rapidly evolving and there are lots of innovative products which we believe are going to make a huge impact. Many of these are just waiting to find the right brand partner. In an effort to be more direct, we&#8217;re introducing our RFP (Requests for Products).</p>
                                    <p className="text-M">Here is a list (we&#8217;ll add to it over time) of product categories we&#8217;re particularly interested in learning more about and finding partners for:</p>
                                </div>
                            </div>
                            <div className="col-md-5 col-md-offset-1 col-sm-12 prod-list">
                                <ol>
                                    <li>
                                        <p>Autonomous Self-flying Cameras</p>
                                    </li>
                                    <li>
                                        <p>Camera Rucksacks</p>
                                    </li>
                                    <li>
                                        <p>Digital Photo Frame</p>
                                    </li>
                                    <li>
                                        <p>Handheld Gimble</p>
                                    </li>
                                    <li>
                                        <p>Facebook Live Camcorder</p>
                                    </li>
                                    <li>
                                        <p>3D Photographic Printer</p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </section>

                    <section className="section brands border-bottom background-grey" id="become-partner">
                        <div className="cta-list container padding-none text-left">
                            <div className="col-xs-12">
                                <h2 className="padding-bottom-M">Become a Partner</h2>
                            </div>
                            <div className="col-md-8 col-sm-12 features">
                                <div className="feature">
                                    <h4>Access to a Specialised Customer Base</h4>
                                    <p>We work directly with independent retailers, corporate incentive schemes, shopping channels and public sector. Our diverse customer base will help you access these hard to reach markets with ease.</p>
                                </div>
                                <div className="feature">
                                    <h4>Integrations with major retailers and platforms</h4>
                                    <p>We&#8217;ve invested heavily in integrations with most major UK retailers. This uniquely allows us to range a large portfolio of products which is impossible alone.</p>
                                </div>
                                <div className="feature">
                                    <h4>Expert Inventory Management</h4>
                                    <p>Having the stock when customers need it matters. We use sophisticated forecasting to ensure we always meet expectations.</p>
                                </div>
                                <div className="feature">
                                    <h4>Products Experts</h4>
                                    <p>Being focused on specific product categories allows our team the opportunity to learn our partner&#8217;s products inside-out. This way we can effectively advise and answer questions where needed.</p>
                                </div>
                                <div className="feature">
                                    <h4>Digital-First Sales &amp; Marketing</h4>
                                    <p>Our online marketing services enables you to tap directly into our database of over 3000 resellers &amp; retailer with automated digital marketing campaigns. From website design to email outbound marketing, our integrated approach will insure your achieve sales goals.</p>
                                </div>
                                <div className="feature">
                                    <h4>Logistics &amp; Warehousing</h4>
                                    <p>We can reliably hold stock and fulfil product orders internationally. Begin ranging products today and we can deliver directly to your customers front door on a next day delivery service.</p>
                                </div>
                                <div className="feature">
                                    <h4>Technical Services</h4>
                                    <p>Our highly trained product and customer service teams will provide pre/post support for any questions you may have. You can contact us via phone, email or online instant messenger!</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="cta-box">
                                    <h3>Become a brand partner today.</h3>
                                    <p>Get in touch to receive our Brand Partner Pack to learn more about our sales channels, purchasing options, margin requirements and marketing offerings.</p>
                                    <p className="phone"><a href="tel:+44%201494%20471100">+44 1494 471100</a></p>
                                    <p><a href="mailto:brandpartners@direktek.co.uk">brandpartners@direktek.co.uk</a></p>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
            </>
        );
    }
}

export default Brands;